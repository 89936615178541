import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from "react";
import { emitTrackingPixel } from "@catchoftheday/analytics";
import { Box, Flex } from "@catchoftheday/cg-components";
import { ProductCardPlaceholder } from "@catchoftheday/common-vendors";
import { ProductCard } from "@catchoftheday/product-components";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ProductGrid = function ProductGrid(_ref) {
  var productItems = _ref.productItems,
      lazyLoad = _ref.lazyLoad,
      sponsoredItemsTracking = _ref.sponsoredItemsTracking,
      _ref$positionOffset = _ref.positionOffset,
      positionOffset = _ref$positionOffset === void 0 ? 0 : _ref$positionOffset;
  return ___EmotionJSX(Flex, {
    flexGrow: 1,
    flexWrap: "wrap"
  }, productItems.map(function (productItem, index) {
    if (productItem === null) {
      return ___EmotionJSX(Box, {
        key: "product-card-placeholder-".concat(index),
        width: {
          sm: "50%",
          lg: "33%",
          xl: "25%"
        },
        pb: "20px",
        flexGrow: 1,
        px: {
          xs: "2px",
          md: "10px"
        }
      }, ___EmotionJSX(ProductCardPlaceholder, {
        uniqueKey: "product-card-placeholder-".concat(index),
        minWidth: "200px",
        minHeight: "443px",
        "aria-label": "Product card placeholder"
      }));
    }

    var sourcePosition = positionOffset + (index + 1);
    return ___EmotionJSX(ProductCard, {
      key: productItem.isSponsored ? "".concat(productItem.id, "-sponsored") : productItem.id,
      lazyLoad: index < 4 ? false : lazyLoad,
      productItem: _objectSpread(_objectSpread({}, productItem), {}, {
        sourcePosition: sourcePosition
      }),
      width: {
        sm: "50%",
        lg: "33%",
        xl: "25%"
      },
      minHeight: "400px",
      height: "auto",
      mb: "20px",
      onProductClick: function onProductClick() {
        if (sponsoredItemsTracking !== null && sponsoredItemsTracking !== void 0 && sponsoredItemsTracking[productItem.id]) {
          emitTrackingPixel(sponsoredItemsTracking[productItem.id].clickTrackingUrl);
        }
      },
      onProductSeen: function onProductSeen() {
        if (sponsoredItemsTracking !== null && sponsoredItemsTracking !== void 0 && sponsoredItemsTracking[productItem.id]) {
          emitTrackingPixel(sponsoredItemsTracking[productItem.id].impressionTrackingUrl);
        }
      }
    });
  }));
};